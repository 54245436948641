import React, { lazy } from "react";
import { Router, Switch, Route, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import PublicRoute from "./PublicRoute.js";
import {
  updateUserApi,
  getAdmin,
  getUserstate
} from "../redux/actions/api/apiAction";
import { store } from "../redux/storeConfig/store";
const createBrowserHistory = require("history").createBrowserHistory;

const LazyLogin = lazy(() => import("../views/pages/login"));
const LazyForgotPassword = lazy(() => import("../views/pages/forgotPassword"));
const LazyReSetPassword = lazy(() => import("../views/pages/resetPassword"));
const LazyContactus = lazy(() => import("../views/pages/contactUs"));
const LazyContactusOption = lazy(() =>
  import("../views/pages/contactUsOption")
);
const LazyDashboard = lazy(() => import("../components/software"));
const LazyAdminInfo = lazy(() => import("../views/pages/admin/info"));
const LazyAdminInfoList = lazy(() => import("../views/pages/admin/adminList"));
const LazyAdminEdit = lazy(() => import("../views/pages/admin/editInfo"));

const LazySoftware = lazy(() =>
  import("../views/pages/dashboard/softwareDashboard")
);
const LazyPayments = lazy(() =>
  import("../views/pages/dashboard/paymentDashboard")
);
const LazyRevenu = lazy(() =>
  import("../views/pages/dashboard/revenueDashboard")
);

const LazyGyminfo = lazy(() => import("../views/pages/gyminfo/gymInfo"));
const LazyGymEdit = lazy(() => import("../views/pages/gyminfo/gyminfoEdit"));
const LazyGyminfoList = lazy(() => import("../views/pages/gyminfo/gymList"));

const LazyTrainerInfoList = lazy(() =>
  import("../views/pages/trainers/trainerInfoList")
);
const LazyTrainerInfo = lazy(() =>
  import("../views/pages/trainers/trainerInfo")
);
const LazyTrainerInfoEdit = lazy(() =>
  import("../views/pages/trainers/trainerEdit")
);
const LazyMembers = lazy(() => import("../views/pages/member/memberList"));

const LazySuperAdminInfo = lazy(() => import("../views/pages/superadmin/info"));
const LazySuperAdminEdit = lazy(() =>
  import("../views/pages/superadmin/superAdminEdit")
);

const LazyError = lazy(() => import("../views/pages/errorPage/error"));
const LazyUnderConstruction = lazy(() =>
  import("../views/pages/errorPage/underConstruction")
);

const LazyAccountListTab = lazy(() => import("../views/pages/accountTabList"));

const LazySocialMedia = lazy(() => import("../views/pages/socialmedia"));
const LazyEmailTemmplate = lazy(() => import("../views/pages/emailTemplate"));

const LazyBookingDetails = lazy(() =>
  import("../components/dashboard/bookingTable")
);

const LazySalesDetails = lazy(() =>
  import("../components/dashboard/salesTable")
);

const LazyOfferSold = lazy(() =>
  import("../components/dashboard/offerSoldTable")
);

const LazyTotalClient = lazy(() =>
  import("../components/dashboard/totalClientTable")
);

export const history = createBrowserHistory({
  basename: "/"
});

const LazyTerms = lazy(() => import("../views/pages/terms"));
const LazyPrivacy = lazy(() => import("../views/pages/privacy"));
const LazyReview = lazy(() => import("../components/review/review"));

class AppRouter extends React.Component {
  // constructor(props) {
  //   super(props);
  // }

  componentDidMount = async () => {
    // //  const {match:{params}}=this.props;
    // // var user = JSON.parse(localStorage.getItem(id));
    // //   await store.dispatch(getAdmin(id));
    // //  var user = JSON.parse(localStorage.getItem('user'));
    // //  await store.dispatch(getAdmin(user));
    // //  console.log("user", user)
    // //  if(res.data.data.id === JSON.parse(localStorage.getItem('user')).id){
    // //  var user = localStorage.setItem('user', JSON.stringify(res.data.data));
    // //   }};
    if (this.props.isAuthenticated) {
      let userData = JSON.parse(localStorage.getItem("user"));
      await store.dispatch(getUserstate(userData.id));
    }
  };
  render() {
    const isAuthenticated = this.props.isAuthenticated;
    let navItems = this.props.loginUserData.permissions
      ? this.props.loginUserData.permissions.map(one => {
          return one.code;
        })
      : [];
    return (
      <Router history={history}>
        <div>
          <Switch>
            <PublicRoute
              path="/"
              component={LazyLogin}
              exact={true}
              fulllayout={true}
            />
            <PublicRoute
              path="/contactus"
              component={LazyContactus}
              fulllayout={true}
              exact
            />
            <PublicRoute
              path="/contactusoption"
              component={LazyContactusOption}
              fulllayout={true}
            />
            {
              <PublicRoute
                path="/dashboard"
                component={LazyDashboard}
                fulllayout={false}
                exact
              />
            }
            <PublicRoute
              path="/dashboard/bookings"
              component={LazyBookingDetails}
              fulllayout={false}
              exact
            />
            <PublicRoute
              path="/dashboard/sales"
              component={LazySalesDetails}
              fulllayout={false}
              exact
            />

            <PublicRoute
              path="/dashboard/offerSold"
              component={LazyOfferSold}
              fulllayout={false}
              exact
            />
            <PublicRoute
              path="/dashboard/totalClient"
              component={LazyTotalClient}
              fulllayout={false}
              exact
            />

            {isAuthenticated && navItems.includes("ADM") && (
              <PublicRoute
                path="/admininfo/:id"
                component={LazyAdminInfo}
                fulllayout={false}
                exact
              />
            )}
            {isAuthenticated && navItems.includes("ADM") && (
              <PublicRoute
                path="/admininfo/:id/edit"
                component={LazyAdminEdit}
                fulllayout={false}
                exact
              />
            )}
            {isAuthenticated && navItems.includes("ADM") && (
              <PublicRoute
                path="/admininfolist"
                component={LazyAdminInfoList}
                fulllayout={false}
                exact
              />
            )}

            {isAuthenticated && navItems.includes("DASH-SFT") && (
              <PublicRoute
                path="/dashboard/software"
                component={LazySoftware}
                fulllayout={false}
                exact
              />
            )}
            <PublicRoute
              path="/memberslist"
              component={LazyMembers}
              fulllayout={false}
              exact
            />
            {isAuthenticated && navItems.includes("DASH-PAY") && (
              <PublicRoute
                path="/dashboard/payments"
                component={LazyPayments}
                fulllayout={false}
                exact
              />
            )}

            {isAuthenticated && navItems.includes("GYM") && (
              <PublicRoute
                path="/gyminfo/:id"
                component={LazyGyminfo}
                fulllayout={false}
                exact
              />
            )}
            {isAuthenticated && navItems.includes("GYM") && (
              <PublicRoute
                path="/gyminfo/:id/edit"
                component={LazyGymEdit}
                fulllayout={false}
                exact
              />
            )}
            {isAuthenticated && navItems.includes("GYM") && (
              <PublicRoute
                path="/gyminfolist"
                component={LazyGyminfoList}
                fulllayout={false}
                exact
              />
            )}

            {isAuthenticated && navItems.includes("TRN") && (
              <PublicRoute
                path="/trainerinfolist"
                component={LazyTrainerInfoList}
                fulllayout={false}
                exact
              />
            )}
            {isAuthenticated && navItems.includes("TRN") && (
              <PublicRoute
                path="/trainerinfo/:id"
                component={LazyTrainerInfo}
                fulllayout={false}
                exact
              />
            )}
            {isAuthenticated && navItems.includes("TRN") && (
              <PublicRoute
                path="/trainerinfo/:id/edit"
                component={LazyTrainerInfoEdit}
                fulllayout={false}
                exact
              />
            )}

            {isAuthenticated && navItems.includes("INF") && (
              <PublicRoute
                path="/superadmininfo"
                component={LazySuperAdminInfo}
                fulllayout={false}
                exact
              />
            )}
            {isAuthenticated && navItems.includes("INF") && (
              <PublicRoute
                path="/superadmininfo/edit"
                component={LazySuperAdminEdit}
                fulllayout={false}
                exact
              />
            )}

            {isAuthenticated && navItems.includes("SMA") && (
              <PublicRoute
                path="/socialmedia"
                component={LazySocialMedia}
                fulllayout={false}
                exact
              />
            )}

            {isAuthenticated && navItems.includes("ACC") && (
              <PublicRoute
                path="/accounttablist"
                component={LazyAccountListTab}
                fulllayout={false}
                exact
              />
            )}

            {isAuthenticated && navItems.includes("ET") && (
              <PublicRoute
                path="/manageemail"
                component={LazyEmailTemmplate}
                fulllayout={false}
                exact
              />
            )}

            <PublicRoute
              path="/forgotPassword"
              component={LazyForgotPassword}
              exact={true}
              fulllayout={true}
            />
            <PublicRoute
              path="/reset-password"
              component={LazyReSetPassword}
              exact={true}
            />
            {isAuthenticated && navItems.includes("DASH-RVS") && (
              <PublicRoute
                path="/dashboard/revenu"
                component={LazyRevenu}
                fulllayout={false}
                exact
              />
            )}
            <PublicRoute
              path="/error"
              component={LazyError}
              fulllayout={false}
              exact
            />
            <PublicRoute
              path="/underconstruction"
              component={LazyUnderConstruction}
              fulllayout={false}
              exact
            />
            {isAuthenticated && navItems.includes("TNC") && (
              <PublicRoute
                path="/terms"
                component={LazyTerms}
                fulllayout={false}
                exact
              />
            )}
            {isAuthenticated && navItems.includes("PP") && (
              <PublicRoute
                path="/privacy"
                component={LazyPrivacy}
                fulllayout={false}
                exact
              />
            )}
            <PublicRoute
              path="/review"
              component={LazyReview}
              fulllayout={false}
              exact
            />
            <Route path="*" render={() => <Redirect to="/dashboard" />} />
          </Switch>
        </div>
      </Router>
    );
  }
}

const mapStateToProps = state => {
  // console.log(state);
  const { loginUserData } = state.login;
  return { isAuthenticated: state.login.loginFlag, loginUserData };
};

export default connect(mapStateToProps)(AppRouter);
