import { SET_GYMS_DATA, SET_GYM_DATA, SET_GYM_COUNT } from '../types/types';

export const setGymsData = (value) => {
  return {
    type: SET_GYMS_DATA,
    payload: { data: value },
  };
};
export const setGymData = (value) => {
  return {
    type: SET_GYM_DATA,
    payload: { data: value },
  };
};
export const setGymCount = (value) => {
  return {
    type: SET_GYM_COUNT,
    payload: { data: value },
  };
};
