import {
  SET_TRAINERS_DATA,
  SET_TRAINER_DATA,
  SET_TRAINER_COUNT,
  
} from '../types/types';

export const setTrainersData = (value) => {
  return {
    type: SET_TRAINERS_DATA,
    payload: { data: value },
  };
};
export const setTrainerData = (value) => {
  return {
    type: SET_TRAINER_DATA,
    payload: { data: value },
  };
};
export const setTrainerCount = (value) => {
  return {
    type: SET_TRAINER_COUNT,
    payload: { data: value },
  };
};

