import React, { useCallback, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Col, Form, FormGroup, Input, Label, Modal, ModalBody, Row } from "reactstrap"
import Title from "../title"
import { addGymSuperAdminModal } from "../../redux/actions/sidebar/sidebarAction"
import ReactFlagsSelect from "react-flags-select"
import PhoneInput from "react-phone-number-input"
import { Field, Formik } from "formik"
import { useDropzone } from "react-dropzone"
import { isValidPhoneNumber } from "react-phone-number-input"
import ReactDatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css";
import Resizer from "react-image-file-resizer";
import { api } from "../../api/api"
import * as Yup from 'yup'
import { createSuperGymAdmin } from "../../redux/actions/api/apiAction"


const Dropzone = ({ values, setFieldValue, errors }) => {
  const onDrop = useCallback(
    acceptedFiles => {
      let file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async event => {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            "PNG",
            100,
            0,
            async uri => {
              let data = {
                fileName: acceptedFiles[0].name,
                source: uri,
                folder: "profile"
              };
              var url = await api(`file`, data, "post");
              if (url.data.status === 201) {
                setFieldValue("image", url.data.data);
              }
            },
            "base64",
            300,
            300
          );
        };
        reader.readAsDataURL(file);
      }
    },
    [setFieldValue]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: 10485760,
    accept: "image/*"
  });

  return (
    <div className="upload-profilephoto">
      <div className="file-input" {...getRootProps()}>
        <h6 className="upload-link mb-0">Upload Picture </h6>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

const formSchema = Yup.object().shape({
  name: Yup.string().required("Gym name is required"),
  userType: Yup.string().required("User Type is required"),
  phone: Yup.number().required("Valid phone number is required "),
  country: Yup.string()
    .typeError("Country is required")
    .required("Country is required"),
  email: Yup.string()
    .email("Please enter valid Username i.e email")
    .required("Username is required"),
  startDate: Yup.string().required("StartDate is required"),
  endDate: Yup.string().required("endDate  is required"),
});

export const SuperGymAdminAddModal = () => {
  const dispatch = useDispatch()
  const { gymSuperAdminModal } = useSelector((state) => state.sidebar)
  const [phoneNumber, setPhoneNumber] = useState(undefined)
  return (
    <Modal
      isOpen={gymSuperAdminModal}
      toggle={() => {
        dispatch(addGymSuperAdminModal(!gymSuperAdminModal));
      }}
      centered={true}
      size="xl"
      className="large-pop adding-popup add-gym-popup "
    >
      <ModalBody>

        <div className="modal-body-content">
          <div className="bg-white card-panel">
            <div className="basic-info">
              <div className="info-title with-border">
                <Title
                  title="Add Gym Super Admin"
                  titlestyle="red"
                  subtitlestyle="ctf-tx-secondary"
                  subtitle="Add Info About Gym"
                />
              </div>
              <Formik
                initialValues={{
                  name: undefined,
                  email: undefined,
                  phone: undefined,
                  startDate: undefined,
                  endDate: undefined,
                  image: undefined,
                  userType: undefined,
                  country: undefined,
                  maxAllowedGyms: undefined,
                }}
                validationSchema={formSchema}
                onSubmit={(values) => {
                  dispatch(createSuperGymAdmin(values))
                }}

              >
                {({
                  errors,
                  touched,
                  handleChange,
                  handleBlur,
                  values,
                  setFieldValue,
                  handleSubmit
                }) => {
                  return (
                    <Form onSubmit={handleSubmit} className="admin-details">
                      <div className="profile-image-block blk-padding-y border-bottom-1">
                        <div className="profile-image-inner-block d-flex justify-content-center">
                          <div className="profile-image-wrapper">
                            <div className="profile-image">
                              <img
                                src={
                                  values.image
                                    ? values.image
                                    : require("../../assets/images/no-image_user.jpg")
                                }
                                className="img-fluid img-blk"
                                alt=""
                              />
                            </div>
                            <div className="profile-image-links">
                              <div className="links file-input-link">
                                <Dropzone
                                  values={values}
                                  setFieldValue={setFieldValue}
                                  errors={errors}
                                />
                              </div>
                              <div className="links">
                                <h6
                                  className="remove-link mb-0 text-uppercase"
                                  onClick={async () => {
                                    // if (values.image) {
                                    //   await api(
                                    //     `file`,
                                    //     { url: values.image },
                                    //     "delete"
                                    //   );
                                    setFieldValue("image", "");
                                    // }
                                  }}
                                >
                                  Remove Picture{" "}
                                </h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="info-wrapper border-bottom-1 blk-padding-y">
                        <div className="info-title">
                          <Title title={"Gym info"} />
                        </div>
                        <div className="info">
                          <Row>
                            <Col md={4}>
                              <FormGroup className="custom-inputs mb-md-0">
                                <Field
                                  type="text"
                                  name="name"
                                  id="adminname"
                                  maxLength={50}
                                  placeholder={
                                    "Gym Name"
                                  }
                                  className={`form-control pr-5 ${errors.name && touched.name && "is-invalid"
                                    }`}
                                />
                                {errors.name && touched.name ? (
                                  <div className="invalid-feedback">{`${errors.name}`}</div>
                                ) : null}
                                <Label for="firstname" className="">
                                  {"Name"}
                                </Label>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup className="custom-inputs mb-md-0">
                                <Field
                                  disabled={false}
                                  type="email"
                                  name="email"
                                  id="adminname"
                                  maxLength={50}
                                  placeholder="Username"
                                  className={`form-control pr-5 ${errors.email && touched.email && "is-invalid"
                                    }`}
                                />
                                {errors.email && touched.email ? (
                                  <div className="invalid-feedback">{`${errors.email}`}</div>
                                ) : null}
                                <Label>Email</Label>
                              </FormGroup>
                            </Col>
                            <Col md={4}>
                              <FormGroup className="custom-inputs mb-md-0">
                                <PhoneInput
                                  international
                                  defaultCountry="JO"
                                  value={values.phone}
                                  placeholder="Phone Number"
                                  onChange={(value) => {
                                    if (value) {
                                      setPhoneNumber(value);
                                      if (isValidPhoneNumber(value)) {
                                        setFieldValue("phone", value);
                                      } else {
                                        setFieldValue("phone", "");
                                      }
                                    }
                                  }}
                                  className={`h3 input-title ${errors.phone && touched.phone && "is-invalid"
                                    }`}
                                />

                                {errors.phone && touched.phone ? (
                                  <div className="invalid-feedback">{`${errors.phone}`}</div>
                                ) : null}
                                <Label>Phone Number</Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="info-wrapper border-bottom-1 blk-padding-y">
                        <div className="info-title">
                          <Title title={"Group Settings"} />
                        </div>
                        <div className="info">
                          <Row>
                            <Col md={4}>
                              <FormGroup className="custom-inputs mb-md-0">
                                <Field
                                  type="number"
                                  name="maxAllowedGyms"
                                  id="maxAllowedGyms"
                                  maxLength={50}
                                  placeholder={
                                    "Max Allowed Gyms"
                                  }
                                  className={`form-control pr-5 ${errors.name && touched.name && "is-invalid"
                                    }`}
                                />
                                {errors.name && touched.name ? (
                                  <div className="invalid-feedback">{`${errors.name}`}</div>
                                ) : null}
                                <Label for="maxAllowedGyms" className="">
                                  {"Max Allowed Gyms"}
                                </Label>
                              </FormGroup>
                            </Col>
                          </Row>
                        </div>
                      </div>

                      <div className="adding-subscription-plan-wrapper border-bottom-1 blk-padding-y">
                        <div className="info-title">
                          <Title title="User Type" />
                        </div>
                        <div className="adding-subscription-plan-wrapper d-block">
                          <div className="subscription-plan-main-block">
                            <ul className="subscription-plan-list-block">
                              <li className="subscription-plan-list-element">
                                <div className="">
                                  <div class="form-check form-check-inline custom-btn-radio">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      name="userType"
                                      id={`userTypeS${"add"}`}
                                      value={"S"}
                                      checked={values.userType === "S" ? true : false}
                                      onChange={(e) => {
                                        // console.log("clicked");
                                        setFieldValue("userType", "S");
                                      }}
                                    />
                                    <label
                                      class="form-check-label label-blk"
                                      for={`userTypeS${"add"}`}
                                    >
                                      {"SAS"}
                                    </label>
                                  </div>
                                </div>
                              </li>

                              <li className="subscription-plan-list-element">
                                <div className="">
                                  <div class="form-check form-check-inline custom-btn-radio">
                                    <input
                                      class="form-check-input"
                                      type="radio"
                                      name="userType"
                                      id={`userTypeR$ "add"}`}
                                      value={"R"}
                                      checked={values.userType === "R" ? true : false}
                                      onChange={(e) => {
                                        // console.log("clicked");
                                        setFieldValue("userType", "R");
                                      }}
                                    />
                                    <label
                                      class="form-check-label label-blk"
                                      for={`userTypeR$ "add"}`}
                                    >
                                      {"Revenue Share"}
                                    </label>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                          {errors.userType ? (
                            <div className="invalid-feedback d-block">{`${errors.userType}`}</div>
                          ) : null}
                        </div>
                      </div>

                      <div className="gym-live-training-wrapper border-bottom-1 blk-padding-y">
                        <div className="info-title">
                          <Title title="Country" />
                        </div>
                        <div className="status-inner-block">
                          <div className="time-select-box d-inline-block">
                            <div className="d-flex align-items-center">
                              <div className="switch-wrapper line-switch">
                                <ReactFlagsSelect
                                  selected={values.country}
                                  countries={["JO", "SA", "AE", "EG", "IQ", "QA"]}
                                  searchable
                                  onSelect={(val) => {
                                    setFieldValue("country", val);
                                  }}
                                />
                                {errors.country ? (
                                  <div className="invalid-feedback d-block">{`${errors.country}`}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="gym-date-edit-wrapper border-bottom-1 blk-padding-y">
                        <div className="date-picker-wrapper">
                          <div className="date-title-block">
                            <h6 className="date-title ctf-tx-secondary mb-0">
                              Date Range
                            </h6>
                          </div>
                          <div className="start-date">
                            <ReactDatePicker
                              selected={values.startDate}
                              minDate={new Date()}
                              name="dob"
                              dateFormat="dd/MM/yyyy"
                              maxDate={values.endDate}
                              onChange={(date) => {
                                if (date) setFieldValue("startDate", date);
                              }}
                            />
                            <div className="d-flex date-picker-img">
                              <img
                                src={require("../../assets/images/date_range.svg")}
                                className="img-fluid"
                                alt=""
                              />
                            </div>
                            {errors.startDate && touched.startDate ? (
                              <div className="invalid-feedback">{`${errors.startDate}`}</div>
                            ) : null}
                          </div>
                          <div className="end-date">
                            <ReactDatePicker
                              selected={values.endDate}
                              minDate={values.startDate}
                              dateFormat="dd/MM/yyyy"
                              onChange={(date) => {
                                if (date) setFieldValue("endDate", date);
                              }}
                            // disabledKeyboardNavigation
                            />
                            <div className="d-flex date-picker-img">
                              <img
                                src={require("../../assets/images/date_range.svg")}
                                className="img-fluid"
                                alt=""
                              />
                              {errors.endDate && touched.endDate ? (
                                <div className="invalid-feedback">{`${errors.endDate}`}</div>
                              ) : null}
                            </div>
                          </div>
                        </div>
                      </div>{" "}

                      <div className="action-wrapper blk-padding-y pb-0">
                        <div className="action-inner-block">
                          <div className="action-btn-block">
                            <button
                              value="cancel"
                              type="reset"
                              className="btn btn-medium btn-transparent"
                              onClick={() => {
                                dispatch(addGymSuperAdminModal(false));
                              }
                              }
                            >
                              cancel
                            </button>
                            <button className="btn btn-medium" type="submit">
                              {"Add"}
                            </button>
                          </div>
                        </div>
                      </div>
                    </Form>
                  );
                }}
              </Formik>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  )
}