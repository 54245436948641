import {
  SET_MEMBER_DATA,
  SET_MEMBERS_DATA,
  SET_MEMBER_COUNT
} from "../types/types";
export const setMemberData = value => {
  return {
    type: SET_MEMBER_DATA,
    payload: { data: value }
  };
};
export const setMemberCount = value => {
  return {
    type: SET_MEMBER_COUNT,
    payload: { data: value }
  };
};
export const setMembersData = value => {
  return {
    type: SET_MEMBERS_DATA,
    payload: { data: value }
  };
};
