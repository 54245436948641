import {
  SET_MEMBER_DATA,
  SET_MEMBERS_DATA,
  SET_MEMBER_COUNT
} from "../../actions/types/types";

const initState = {
  member: {},
  members: [],
  allMembers: [],
  memberCount: 0
};

const MemberReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_MEMBER_DATA:
      return {
        ...state,
        member: action.payload.data
      };
    case SET_MEMBER_COUNT:
      return {
        ...state,
        memberCount: action.payload.data
      };

    case SET_MEMBERS_DATA:
      return {
        ...state,
        members: action.payload.data
      };

    default:
      return { ...state };
  }
};

export default MemberReducer;
