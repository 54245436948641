import React, { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import * as Yup from "yup";
import { Formik, Field, Form } from "formik";
import Title from "../../../components/title";
import { Input, FormGroup, Label, Row, Col } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { addGymModal } from "../../../redux/actions/sidebar/sidebarAction";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../../app/AppRouter";
import Resizer from "react-image-file-resizer";
import { api } from "../../../api/api";
import "react-phone-number-input/style.css";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import moment from "moment";
import { toastr } from "react-redux-toastr";
import ReactFlagsSelect from "react-flags-select";

const formSchema = Yup.object().shape({
  name: Yup.string().required("Gym name is required"),
  userType: Yup.string().required("User Type is required"),
  phone: Yup.number().required("Valid phone number is required "),
  country: Yup.string()
    .typeError("Country is required")
    .required("Country is required"),
  email: Yup.string()
    .email("Please enter valid Username i.e email")
    .required("Username is required"),
  startDate: Yup.string().required("StartDate is required"),
  endDate: Yup.string().required("endDate  is required"),
  subscriptionPlan: Yup.number("Subscription plan is required").required(
    "Subscription plan is required"
  ),
});

const DropZone = ({ values, setFieldValue, errors }) => {
  const onDrop = useCallback(
    (acceptedFiles) => {
      let file = acceptedFiles[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = async (event) => {
          Resizer.imageFileResizer(
            file,
            300,
            300,
            "PNG",
            100,
            0,
            async (uri) => {
              let data = {
                fileName: acceptedFiles[0].name,
                source: uri,
                folder: "profile",
              };
              var url = await api(`file`, data, "post");
              if (url.data.status === 201) {
                setFieldValue("image", url.data.data);
              }
            },
            "base64",
            300,
            300
          );
        };
        reader.readAsDataURL(file);
      }
    },
    [setFieldValue]
  );
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    minSize: 0,
    maxSize: 10485760,
    accept: "image/*",
  });

  return (
    <div className="upload-profilephoto">
      <div className="file-input" {...getRootProps()}>
        <h6 className="upload-link mb-0">Upload Picture </h6>
        <input {...getInputProps()} />
      </div>
    </div>
  );
};

function GymForm({
  name,
  email,
  phone,
  subscriptionPlan,
  startDate,
  endDate,
  canLive,
  handleSubmit,
  edit,
  editTrainer,
  image,
  userType,
  country,
  ...props
}) {
  // console.log("HHHH", country);
  const dispatch = useDispatch();
  let plan = useSelector((state) => state.plan.plan);
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isPhoneNumberValid, setisPhoneNumberValid] = useState(true);
  useEffect(() => {
    const isValid =
      phoneNumber && isValidPhoneNumber(phoneNumber) ? true : false;
    setisPhoneNumberValid(isValid);
  }, [phoneNumber]);

  // function handleChangeRaw(value) {
  //   if(value === "tomorrow") {
  //     const tomorrow = moment().add(1, "day")
  //     this.handleChange(tomorrow)
  //   }
  // }
  //   const Input = ({onChange, placeholder, value, isSecure, id, onClick}) => (
  //     <input
  //         onChange={onChange}
  //         placeholder={placeholder}
  //        value="string | number"
  //         isSecure={isSecure}
  //         id={id}
  //         onClick={onClick}
  //     />
  // );

  return (
    <React.Fragment>
      <Formik
        initialValues={{
          name,
          email,
          phone,
          subscriptionPlan: subscriptionPlan || "",
          startDate,
          endDate,
          canLive,
          image,
          userType,
          country,
        }}
        validationSchema={formSchema}
        onSubmit={handleSubmit}
      >
        {({
          errors,
          touched,
          handleChange,
          handleBlur,
          values,
          setFieldValue,
        }) => {
          // console.log(values, "HeRE");
          return (
            <Form className="admin-details">
              <div className="profile-image-block blk-padding-y border-bottom-1">
                <div className="profile-image-inner-block d-flex justify-content-center">
                  <div className="profile-image-wrapper">
                    <div className="profile-image">
                      <img
                        src={
                          values.image
                            ? values.image
                            : require("../../../assets/images/no-image_user.jpg")
                        }
                        className="img-fluid img-blk"
                        alt=""
                      />
                    </div>
                    <div className="profile-image-links">
                      <div className="links file-input-link">
                        <DropZone
                          values={values}
                          setFieldValue={setFieldValue}
                          errors={errors}
                        />
                      </div>
                      <div className="links">
                        <h6
                          className="remove-link mb-0 text-uppercase"
                          onClick={async () => {
                            // if (values.image) {
                            //   await api(
                            //     `file`,
                            //     { url: values.image },
                            //     "delete"
                            //   );
                            setFieldValue("image", "");
                            // }
                          }}
                        >
                          Remove Picture{" "}
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="info-wrapper border-bottom-1 blk-padding-y">
                <div className="info-title">
                  <Title title={editTrainer ? "Trainer info" : "Gym info"} />
                </div>
                <div className="info">
                  <Row>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <Field
                          type="text"
                          name="name"
                          id="adminname"
                          maxLength={50}
                          placeholder={
                            editTrainer ? "Trainer Name" : "Gym Name"
                          }
                          className={`form-control pr-5 ${
                            errors.name && touched.name && "is-invalid"
                          }`}
                        />
                        {errors.name && touched.name ? (
                          <div className="invalid-feedback">{`${errors.name}`}</div>
                        ) : null}
                        <Label for="firstname" className="">
                          {editTrainer ? "Trainer Name" : "Gym Name"}
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <Field
                          disabled={edit}
                          type="email"
                          name="email"
                          id="adminname"
                          maxLength={50}
                          placeholder="Username"
                          className={`form-control pr-5 ${
                            errors.email && touched.email && "is-invalid"
                          }`}
                        />
                        {errors.email && touched.email ? (
                          <div className="invalid-feedback">{`${errors.email}`}</div>
                        ) : null}
                        <Label>User Name</Label>
                      </FormGroup>
                    </Col>
                    <Col md={4}>
                      <FormGroup className="custom-inputs mb-md-0">
                        <PhoneInput
                          international
                          defaultCountry="JO"
                          value={values.phone}
                          className="h3 input-title"
                          placeholder="Phone Number"
                          onChange={(value) => {
                            if (value) {
                              setPhoneNumber(value);
                              if (isValidPhoneNumber(value)) {
                                // console.log("Here", value);
                                setFieldValue("phone", value);
                              } else {
                                setFieldValue("phone", "");
                              }
                            }
                          }}
                          className={` ${
                            errors.phone && touched.phone && "is-invalid"
                          }`}
                        />
                        {/* {errors.phone && touched.phone ? (
                            <div className="invalid-feedback">{`${errors.phone}`}</div>
                          ) : null} */}

                        {errors.phone && touched.phone ? (
                          <div className="invalid-feedback">{`${errors.phone}`}</div>
                        ) : null}
                        <Label>Phone Number</Label>
                        {/* {isPhoneNumberValid ? null : <span>invalid</span>} */}

                        {/* </div> */}
                        {/* <Field
                          type='number'
                          name='phone'
                          id='adminname'
                          maxLength={20}
                          minLength={10}
                          placeholder='+123'
                          className={`form-control ${
                            errors.phone && touched.phone && 'is-invalid'
                          }`}
                        /> */}
                        {/* {errors.phone && touched.phone ? (
                          <div className='invalid-feedback'>{`${errors.phone}`}</div>
                        ) : null}
                        <Label>Phone Number</Label> */}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
              </div>
              <div className="adding-subscription-plan-wrapper border-bottom-1 blk-padding-y">
                <div className="info-title">
                  <Title title="Subscription plan" />
                </div>
                <div className="adding-subscription-plan-wrapper d-block">
                  <div className="subscription-plan-main-block">
                    {/* <div className="amenities-inner-block">
                  <div className="amenities-selected">
                    <div className="amenities-list">
                      <div className="capsual-list">
                      <Capsual title={subscriptionPlan && subscriptionPlan.name} />
                      </div>
                    </div>
                  </div>
                </div> */}
                    <ul className="subscription-plan-list-block">
                      {plan &&
                        plan.map((value, index) => {
                          // console.log("Plan", value);
                          return (
                            <li className="subscription-plan-list-element">
                              <div className="">
                                <div class="form-check form-check-inline custom-btn-radio">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="subscriptionPlan"
                                    id={`${value.id}${index}${
                                      edit ? "edit" : "add"
                                    }`}
                                    value={value.id}
                                    checked={
                                      values.subscriptionPlan === value.id
                                        ? true
                                        : false
                                    }
                                    onChange={(e) => {
                                      // console.log("clicked");
                                      setFieldValue(
                                        "subscriptionPlan",
                                        parseInt(e.target.value)
                                      );
                                    }}
                                  />
                                  <label
                                    class="form-check-label label-blk"
                                    for={`${value.id}${index}${
                                      edit ? "edit" : "add"
                                    }`}
                                  >
                                    {value.name}
                                    {/* {console.log("plantoggle", value.id)} */}
                                  </label>
                                </div>
                              </div>
                            </li>
                          );
                        })}
                    </ul>
                  </div>
                  {errors.subscriptionPlan ? (
                    <div className="invalid-feedback d-block">{`${errors.subscriptionPlan}`}</div>
                  ) : null}
                </div>
              </div>
              {/* <div className="adding-subscription-plan-wrapper border-bottom-1 blk-padding-y"> */}
              {/* <div className="info-title">
                  <Title title="User Type" />
                </div>
                <div className="adding-subscription-plan-wrapper d-block">
                  <div className="subscription-plan-main-block">
                    {/* <div className="amenities-inner-block">
                  <div className="amenities-selected">
                    <div className="amenities-list">
                      <div className="capsual-list">
                      <Capsual title={subscriptionPlan && subscriptionPlan.name} />
                      </div>
                    </div>
                  </div>
                </div> */}
              {/* <ul className="subscription-plan-list-block">
                     
                            <li className="subscription-plan-list-element">
                              <div className="">
                                <div class="form-check form-check-inline custom-btn-radio">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="subscriptionPlan"
                                  />
                                  <label
                                    class="form-check-label label-blk"
                                    // for={SAAS}
                                  >
                                     SAS
                                  </label>
                                </div>
                              </div>
                            </li>
                            <li className="subscription-plan-list-element">
                              <div className="">
                                <div class="form-check form-check-inline custom-btn-radio">
                                  <input
                                    class="form-check-input"
                                    type="radio"
                                    name="subscriptionPlan"
                                  />
                                  <label
                                    class="form-check-label label-blk"
                                    // for={SAAS}
                                  >
                                     Revenue Share
                                  </label>
                                </div>
                              </div>
                            </li>
                        
                    </ul>
                  </div>
                </div> 
              </div> */}
              <div className="adding-subscription-plan-wrapper border-bottom-1 blk-padding-y">
                <div className="info-title">
                  <Title title="User Type" />
                </div>
                <div className="adding-subscription-plan-wrapper d-block">
                  <div className="subscription-plan-main-block">
                    <ul className="subscription-plan-list-block">
                      <li className="subscription-plan-list-element">
                        <div className="">
                          <div class="form-check form-check-inline custom-btn-radio">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="userType"
                              id={`userTypeS${edit ? "edit" : "add"}`}
                              value={"S"}
                              checked={values.userType === "S" ? true : false}
                              onChange={(e) => {
                                // console.log("clicked");
                                setFieldValue("userType", "S");
                              }}
                            />
                            <label
                              class="form-check-label label-blk"
                              for={`userTypeS${edit ? "edit" : "add"}`}
                            >
                              {"SAS"}
                            </label>
                          </div>
                        </div>
                      </li>

                      <li className="subscription-plan-list-element">
                        <div className="">
                          <div class="form-check form-check-inline custom-btn-radio">
                            <input
                              class="form-check-input"
                              type="radio"
                              name="userType"
                              id={`userTypeR${edit ? "edit" : "add"}`}
                              value={"R"}
                              checked={values.userType === "R" ? true : false}
                              onChange={(e) => {
                                // console.log("clicked");
                                setFieldValue("userType", "R");
                              }}
                            />
                            <label
                              class="form-check-label label-blk"
                              for={`userTypeR${edit ? "edit" : "add"}`}
                            >
                              {"Revenue Share"}
                            </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  {errors.userType ? (
                    <div className="invalid-feedback d-block">{`${errors.userType}`}</div>
                  ) : null}
                </div>
              </div>
              <div className="gym-live-training-wrapper border-bottom-1 blk-padding-y">
                <div className="info-title">
                  <Title title="Country" />
                </div>
                <div className="status-inner-block">
                  <div className="time-select-box d-inline-block">
                    <div className="d-flex align-items-center">
                      <div className="switch-wrapper line-switch">
                        <ReactFlagsSelect
                          selected={values.country}
                          countries={["JO", "SA", "AE", "EG", "IQ", "QA"]}
                          searchable
                          onSelect={(val) => {
                            setFieldValue("country", val);
                          }}
                        />
                        {errors.country ? (
                          <div className="invalid-feedback d-block">{`${errors.country}`}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="gym-live-training-wrapper border-bottom-1 blk-padding-y">
                <div className="info-title">
                  <Title title="Live training" />
                </div>
                <div className="status-inner-block">
                  <div className="time-select-box d-inline-block">
                    <div className="d-flex align-items-center">
                      <div className="switch-wrapper line-switch">
                        {/* {console.log("cl",values.canLive)} */}
                        <Input
                          type="checkbox"
                          id={`switchBox2${edit ? "edit" : "add"}`}
                          className="switch-input"
                          checked={values.canLive}
                          onChange={(e) => {
                            setFieldValue("canLive", !values.canLive);
                          }}
                        />

                        <Label
                          for={`switchBox2${edit ? "edit" : "add"}`}
                          className="switch-label"
                        ></Label>
                      </div>
                    </div>
                  </div>
                  <div className="d-inline-block">
                    {values.canLive ? (
                      <h3 className="ctf-tx-fifth">Enabled</h3>
                    ) : (
                      <h3 className="ctf-tx-fifth text-danger">Disabled</h3>
                    )}
                  </div>
                </div>
              </div>
              <div className="gym-date-edit-wrapper border-bottom-1 blk-padding-y">
                <div className="date-picker-wrapper">
                  <div className="date-title-block">
                    <h6 className="date-title ctf-tx-secondary mb-0">
                      Date Range
                    </h6>
                  </div>
                  <div className="start-date">
                    <DatePicker
                      selected={values.startDate}
                      minDate={new Date()}
                      name="dob"
                      dateFormat="dd/MM/yyyy"
                      maxDate={values.endDate}
                      onChange={(date) => {
                        if (date) setFieldValue("startDate", date);
                      }}
                    />
                    <div className="d-flex date-picker-img">
                      <img
                        src={require("../../../assets/images/date_range.svg")}
                        className="img-fluid"
                        alt=""
                      />
                    </div>
                    {errors.startDate && touched.startDate ? (
                      <div className="invalid-feedback">{`${errors.startDate}`}</div>
                    ) : null}
                  </div>
                  <div className="end-date">
                    <DatePicker
                      selected={values.endDate}
                      minDate={values.startDate}
                      dateFormat="dd/MM/yyyy"
                      onChange={(date) => {
                        if (date) setFieldValue("endDate", date);
                      }}
                      // disabledKeyboardNavigation
                    />
                    <div className="d-flex date-picker-img">
                      <img
                        src={require("../../../assets/images/date_range.svg")}
                        className="img-fluid"
                        alt=""
                      />
                      {errors.endDate && touched.endDate ? (
                        <div className="invalid-feedback">{`${errors.endDate}`}</div>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>{" "}
              <div className="action-wrapper blk-padding-y pb-0">
                <div className="action-inner-block">
                  <div className="action-btn-block">
                    <button
                      value="cancel"
                      type="reset"
                      className="btn btn-medium btn-transparent"
                      onClick={() => {
                        if (editTrainer) {
                          history.goBack();
                        } else {
                          edit
                            ? history.goBack()
                            : dispatch(addGymModal(false));
                        }
                      }}
                    >
                      cancel
                    </button>
                    <button className="btn btn-medium" type="submit">
                      {edit ? "Save changes" : "Add Gym"}
                    </button>
                  </div>
                </div>
              </div>
            </Form>
          );
        }}
      </Formik>
    </React.Fragment>
  );
}

GymForm.defaultProps = {
  name: "",
  email: "",
  phone: "",
  subscriptionPlan: {},
  startDate: new Date(),
  endDate: new Date(),
  activeStatus: "ACTIVE",
  canLive: true,
  handleSubmit: (values) => {},
  edit: false,
  editTrainer: false,
  user: false,
  type: "",
  image: "",
  userType: "",
  country: "",
};

export default GymForm;
