import {
  SET_TRAINER_DATA,
  SET_TRAINERS_DATA,
  SET_TRAINER_COUNT,
  ADD_NEW_TRAINER,
} from '../../actions/types/types';

const initState = {
  trainers: [],
  trainer: {},
  trainerCount: 0,
};

const TrainerReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_TRAINER_DATA:
      return {
        ...state,
        trainer: action.payload.data,
      };
    case SET_TRAINERS_DATA:
      return {
        ...state,
        trainers: action.payload.data,
      };
    case SET_TRAINER_COUNT:
      return {
        ...state,
        trainerCount: action.payload.data,
      };
      case ADD_NEW_TRAINER:
        
        return {
          ...state,
          trainers: [...state.trainers,action.payload.data],
        };
    default:
      return { ...state };
  }
};

export default TrainerReducer;

