import {
  SET_GYM_DATA,
  SET_GYMS_DATA,
  SET_GYM_COUNT,
} from '../../actions/types/types';

const initState = {
  gyms: [],
  gym: {},
  gymCount: 0,
};

const GymReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_GYM_DATA:
      return {
        ...state,
        gym: action.payload.data,
      };
    case SET_GYMS_DATA:
      return {
        ...state,
        gyms: action.payload.data,
      };
    case SET_GYM_COUNT:
      return {
        ...state,
        gymCount: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default GymReducer;
