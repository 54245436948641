import { ADD_GYM_MODAL, SET_SIDEBAR_STATUS , ADD_TRAINER_MODAL ,ADD_ADMIN_MODAL, ADD_GYM_SUPER_ADMIN_MODAL} from '../../actions/types/types';

const initState = {
  sidebarStatus:false,
  gymModal:false,
  trainerModal:false,
  adminModal: false,
  gymSuperAdminModal:false
};

const sidebarReducer = (state = initState, action) => {
  switch (action.type) {
    case SET_SIDEBAR_STATUS:
      return {
        ...state,
        sidebarStatus: action.payload.data,
      };
      case ADD_GYM_MODAL:
      return {
        ...state,
        gymModal: action.payload.data,
      };
      case ADD_TRAINER_MODAL:
      return {
        ...state,
        trainerModal: action.payload.data,
      };
      case ADD_ADMIN_MODAL:
      return {
        ...state,
        adminModal: action.payload.data,
      };
      case ADD_GYM_SUPER_ADMIN_MODAL:
      return {
        ...state,
        gymSuperAdminModal: action.payload.data,
      };
    default:
      return { ...state };
  }
};

export default sidebarReducer;
