import React, { useState } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { useDispatch } from 'react-redux';
import {
  addGymModal,
  addTrainerModal,
  addAdminModal,
  addGymSuperAdminModal,
} from '../../redux/actions/sidebar/sidebarAction';
import { getPlans, getPermissions } from '../../redux/actions/api/apiAction';
const AddDropdown = (props) => {
  const [dropdownOpen, setOpen] = useState(false);
  const dispatch = useDispatch();

  const toggle = () => setOpen(!dropdownOpen);
  return (
    <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} className='w-100'>
      <DropdownToggle
        caret
        className='custom-dropdown-button w-100'
        color='transparent'
      >
        Add New User
      </DropdownToggle>
      <DropdownMenu className='add-dropdown-menu'>
        {props.permissions.includes('GYM') && <DropdownItem
          onClick={async () => {
            await dispatch(getPlans());
            dispatch(addGymModal(true));
          }}
        >
          <span className='block-1'>
            <svg
              width='24px'
              height='24px'
              viewBox='0 0 24 24'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g
                id='Admin-account'
                stroke='none'
                stroke-width='1'
                fill='none'
                fill-rule='evenodd'
                opacity='0.5'
              >
                <g
                  id='Admin-Dashboard'
                  transform='translate(-32.000000, -594.000000)'
                >
                  <g id='Side-Menu' transform='translate(0.000000, 70.000000)'>
                    <g id='Gyms' transform='translate(0.000000, 513.000000)'>
                      <g
                        id='fitness_center-24px'
                        transform='translate(32.000000, 11.000000)'
                      >
                        <polygon
                          id='Path'
                          points='0 0 24 0 24 24 0 24'
                        ></polygon>
                        <path
                          d='M20.57,14.86 L21.29,14.14 C21.68,13.75 21.68,13.12 21.29,12.73 L21.27,12.71 C20.88,12.32 20.25,12.32 19.86,12.71 L17,15.57 L8.43,7 L11.29,4.14 C11.68,3.75 11.68,3.12 11.29,2.73 L11.27,2.71 C10.88,2.32 10.25,2.32 9.86,2.71 L9.14,3.43 L8.42,2.71 C8.03,2.32 7.39,2.32 7,2.71 L5.57,4.14 L4.85,3.42 C4.46,3.03 3.81,3.03 3.42,3.42 C3.03,3.81 3.03,4.46 3.42,4.85 L4.14,5.57 L2.71,7 C2.32,7.39 2.32,8.02 2.71,8.41 L3.43,9.13 L2.71,9.86 C2.32,10.25 2.32,10.88 2.71,11.27 L2.73,11.29 C3.12,11.68 3.75,11.68 4.14,11.29 L7,8.43 L15.57,17 L12.71,19.86 C12.32,20.25 12.32,20.88 12.71,21.27 L12.73,21.29 C13.12,21.68 13.75,21.68 14.14,21.29 L14.86,20.57 L15.58,21.29 C15.97,21.68 16.6,21.68 16.99,21.29 L18.42,19.86 L19.14,20.58 C19.53,20.97 20.18,20.97 20.57,20.58 C20.96,20.19 20.96,19.54 20.57,19.15 L19.85,18.43 L21.29,17 C21.68,16.61 21.68,15.98 21.29,15.59 L20.57,14.86 Z'
                          id='Path'
                          fill='#000000'
                          fill-rule='nonzero'
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span className='block-2'>Add Gym</span>
        </DropdownItem>}
        {props.permissions.includes('TRN') &&<DropdownItem
          onClick={() => {
            dispatch(addTrainerModal(true));
          }}
        >
          <span className='block-1'>
            {' '}
            <svg
              width='24px'
              height='24px'
              viewBox='0 0 24 24'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g
                id='Admin-account'
                stroke='none'
                stroke-width='1'
                fill='none'
                fill-rule='evenodd'
                opacity='0.5'
              >
                <g
                  id='Admin-Dashboard'
                  transform='translate(-32.000000, -648.000000)'
                >
                  <g id='Side-Menu' transform='translate(0.000000, 70.000000)'>
                    <g
                      id='Trainers'
                      transform='translate(0.000000, 567.000000)'
                    >
                      <g
                        id='self_improvement-24px'
                        transform='translate(32.000000, 11.000000)'
                      >
                        <g id='Group'>
                          <rect
                            id='Rectangle'
                            x='0'
                            y='0'
                            width='24'
                            height='24'
                          ></rect>
                          <rect
                            id='Rectangle'
                            x='0'
                            y='0'
                            width='24'
                            height='24'
                          ></rect>
                        </g>
                        <g
                          id='Group'
                          transform='translate(3.000000, 4.000000)'
                          fill='#000000'
                          fill-rule='nonzero'
                        >
                          <circle id='Oval' cx='9' cy='2' r='2'></circle>
                          <path
                            d='M18,10.94 L18,10.94 C18,10.44 17.64,10.01 17.15,9.96 C15.27,9.75 13.66,8.83 12.4,7.33 L11.06,5.73 C10.68,5.26 10.12,5 9.53,5 L8.48,5 C7.89,5 7.33,5.26 6.95,5.72 L5.61,7.32 C4.36,8.82 2.74,9.74 0.86,9.95 C0.36,10.01 0,10.44 0,10.94 L0,10.94 C0,11.54 0.53,12.01 1.13,11.94 C3.43,11.67 5.45,10.55 7,8.75 L7,11 L3.24,12.5 C2.59,12.76 2.08,13.33 2.01,14.03 C1.91,15.1 2.74,16 3.79,16 L6,16 L6,15.5 C6,14.12 7.12,13 8.5,13 L11.5,13 C11.78,13 12,13.22 12,13.5 C12,13.78 11.78,14 11.5,14 L8.5,14 C7.67,14 7,14.67 7,15.5 L7,16 L14.1,16 C14.95,16 15.75,15.46 15.95,14.63 C16.16,13.74 15.68,12.87 14.87,12.55 L11,11 L11,8.75 C12.56,10.55 14.57,11.66 16.87,11.94 C17.47,12 18,11.54 18,10.94 Z'
                            id='Path'
                          ></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>{' '}
          </span>{' '}
          <span className='block-2'>Add Trainer</span>
        </DropdownItem>}
        {props.permissions.includes('ADM') && <DropdownItem
          onClick={async () => {
            await dispatch(getPermissions());
            dispatch(addGymSuperAdminModal(true));
          }}
        >
          <span className='block-1'>
            <svg xmlns="http://www.w3.org/2000/svg" width="24px" height="24px" viewBox="0 -0.5 17 17" version="1.1" class="si-glyph si-glyph-city" fill="#000000">

              <g id="SVGRepo_bgCarrier" stroke-width="0" />

              <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" />

              <g id="SVGRepo_iconCarrier"> <title>717</title> <defs> </defs> <g stroke="none" stroke-width="1" fill="none" fill-rule="evenodd"> <g transform="translate(1.000000, 0.000000)" fill="#434343"> <path d="M0,8 L0,16 L3,16 L3,8 L0,8 L0,8 Z M2,15 L0.979,15 L0.979,12.979 L2,12.979 L2,15 L2,15 Z M2.021,12 L0.98,12 L0.98,9.979 L2.021,9.979 L2.021,12 L2.021,12 Z" class="si-glyph-fill"> </path> <path d="M9,5 L9,3 L8,3 L8,0 L7,0 L7,3 L6,3 L6,5 L4,5 L4,16 L11,16 L11,5 L9,5 L9,5 Z M6,15 L5,15 L5,13 L6,13 L6,15 L6,15 Z M6,12.042 L5,12.042 L5,10 L6,10 L6,12.042 L6,12.042 Z M6,9.042 L5,9.042 L5,7 L6,7 L6,9.042 L6,9.042 Z M8,15 L7,15 L7,13 L8,13 L8,15 L8,15 Z M8,12.042 L7,12.042 L7,10 L8,10 L8,12.042 L8,12.042 Z M8,9.042 L7,9.042 L7,7 L8,7 L8,9.042 L8,9.042 Z M10,15 L9,15 L9,13 L10,13 L10,15 L10,15 Z M10,12.042 L9,12.042 L9,10 L10,10 L10,12.042 L10,12.042 Z M10,9.042 L9,9.042 L9,7 L10,7 L10,9.042 L10,9.042 Z" class="si-glyph-fill"> </path> <path d="M12,7 L12,16 L16,16 L16,11 L12,7 L12,7 Z M14.031,15.062 L13,15.062 L13,13.968 L14.031,13.968 L14.031,15.062 L14.031,15.062 Z M14.031,13.062 L13,13.062 L13,11.968 L14.031,11.968 L14.031,13.062 L14.031,13.062 Z M14.031,11.062 L13,11.062 L13,9.968 L14.031,9.968 L14.031,11.062 L14.031,11.062 Z" class="si-glyph-fill"> </path> </g> </g> </g>

            </svg>
          </span>
          <span className='block-2'> Add Gym Super Admin</span>
        </DropdownItem>}
        {props.permissions.includes('ADM') && <DropdownItem
          onClick={async () => {
            await dispatch(getPermissions());
            dispatch(addAdminModal(true));
          }}
        >
          <span className='block-1'>
            <svg
              width='24px'
              height='24px'
              viewBox='0 0 24 24'
              version='1.1'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g
                id='Admin-account'
                stroke='none'
                stroke-width='1'
                fill='none'
                fill-rule='evenodd'
                opacity='0.5'
              >
                <g id='Gym-Info' transform='translate(-32.000000, -836.000000)'>
                  <g
                    id='Social-media'
                    transform='translate(0.000000, 825.000000)'
                  >
                    <g
                      id='admin_panel_settings-24px'
                      transform='translate(32.000000, 11.000000)'
                    >
                      <g id='Group'>
                        <rect
                          id='Rectangle'
                          x='0'
                          y='0'
                          width='24'
                          height='24'
                        ></rect>
                        <rect
                          id='Rectangle'
                          x='0'
                          y='0'
                          width='24'
                          height='24'
                        ></rect>
                      </g>
                      <g
                        id='Group'
                        transform='translate(3.000000, 3.000000)'
                        fill='#000000'
                        fill-rule='nonzero'
                      >
                        <path
                          d='M14,8 C14.34,8 14.67,8.04 15,8.09 L15,4.58 C15,3.78 14.53,3.06 13.8,2.75 L8.3,0.35 C7.79,0.13 7.21,0.13 6.7,0.35 L1.2,2.75 C0.47,3.07 0,3.79 0,4.58 L0,8.18 C0,12.72 3.2,16.97 7.5,18 C8.05,17.87 8.58,17.68 9.1,17.45 C8.41,16.47 8,15.28 8,14 C8,10.69 10.69,8 14,8 Z'
                          id='Path'
                        ></path>
                        <path
                          d='M14,10 C11.79,10 10,11.79 10,14 C10,16.21 11.79,18 14,18 C16.21,18 18,16.21 18,14 C18,11.79 16.21,10 14,10 Z M14,11.38 C14.62,11.38 15.12,11.89 15.12,12.5 C15.12,13.11 14.61,13.62 14,13.62 C13.39,13.62 12.88,13.11 12.88,12.5 C12.88,11.89 13.38,11.38 14,11.38 Z M14,16.75 C13.07,16.75 12.26,16.29 11.76,15.58 C11.81,14.86 13.27,14.5 14,14.5 C14.73,14.5 16.19,14.86 16.24,15.58 C15.74,16.29 14.93,16.75 14,16.75 Z'
                          id='Shape'
                        ></path>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
          <span className='block-2'> Add Admin</span>
        </DropdownItem>}
      </DropdownMenu>
    </ButtonDropdown>
  );
};

export default AddDropdown;
